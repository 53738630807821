import React, { memo } from "react";
import useSWR from "swr";
import MUIDataTable, { MUIDataTableColumnDef } from "mui-datatables";
import { useAuth0 } from "../react-auth0-spa";
import fetcherWithToken from "../utils/fetcher";
import Loading from "../components/Loading";
import startCase from "lodash.startcase";

interface EventsTableProps {
  startDate: Date | null;
  endDate: Date | null;
  fcode?: string;
}

type EventResult = {
  eventType: string;
  date: string;
  impactType: string;
  source?: string;
  detail?: string;
};

const eventsColumns: MUIDataTableColumnDef[] = [
  {
    name: "eventType",
    label: "Event Type",
  },
  {
    name: "impactType",
    label: "Impact Type",
  },
  {
    name: "detail",
    label: "Detail",
    options: {
      filter: false,
    },
  },
  {
    name: "date",
    label: "Date",
    options: {
      filter: false,
      sortDirection: "desc",
      customBodyRender: (value: string) => {
        const date = Date.parse(value) ? new Date(value).toDateString() : null;
        return <time style={{ whiteSpace: "nowrap" }}>{date}</time>;
      },
    },
  },
];

function formatType(iri: string) {
  return startCase(iri.split("#").pop());
}

function formatEventsResults(results: any[] | undefined) {
  let resultObject = {} as EventResult;
  const rows = results
    ? results.reduce((acc, curr) => {
        const { event_type, impact_type, date, source, note } = curr;
        resultObject = {
          eventType: formatType(event_type.value),
          impactType: formatType(impact_type.value),
          detail: note?.value,
          date: date.value,
          source: source?.value,
        };
        acc.push(Object.values(resultObject));
        return acc;
      }, [])
    : [];
  return { rows, columns: Object.keys(resultObject) };
}

function toIsoStringDate(date: Date | null) {
  return date?.toISOString().split("T")[0];
}

function createEventsTable(events: any | undefined, marketData: any) {
  const eventRows = formatEventsResults(events?.results?.bindings)?.rows || [];
  const ytd =
    marketData?.InstrumentResponses[0]?.Matches[0]?.TradingStatistics
      ?.PriceStatistics[1];

  const latestYTDHigh = [
    "Latest YTD High/Low",
    "High",
    `$${ytd?.Maximum?.Value}`,
    ytd?.MaximumDateTime,
  ];
  const latestYTDLow = [
    "Latest YTD High/Low",
    "Low",
    `$${ytd?.Minimum?.Value}`,
    ytd?.MinimumDateTime,
  ];
  const marketDataRows = marketData ? [latestYTDHigh, latestYTDLow] : [];
  return [...eventRows, ...marketDataRows];
}

//TODO: Remove the hardocded query when an endpoint abstracts these events
function parametrizeQuery(
  startDate: Date | null,
  endDate: Date | null,
  fcode: string | undefined
) {
  return `
prefix gdnc: <http://data.dowjones.ai/ontologies/guidance#>
prefix cco: <http://www.ontologyrepository.com/CommonCoreOntologies/>
prefix dcterms: <http://purl.org/dc/terms/>
prefix skos: <http://www.w3.org/2004/02/skos/core#>
prefix time: <http://www.w3.org/2006/time#> 

select
DISTINCT
*
where {
    VALUES ?event_type { <http://data.dowjones.ai/ontologies/guidance#Guidance> <http://data.dowjones.ai/ontologies/guidance#EarningsStatement>}
    BIND (<http://data.dowjones.ai/entity/cande/company/fcoded/${fcode}> as ?company)
    ?event a ?event_type;
           cco:has_agent ?company;
           cco:occurs_on ?time;
           gdnc:has_guidance_impact|gdnc:has_earnings_valence ?impact_type.
    ?time time:inXSDDateTimeStamp ?date.
    OPTIONAL {
       ?event dcterms:source ?source;
              skos:editorialNote ?note.
    }
       filter(?date >= "${toIsoStringDate(
         startDate
       )}" && ?date <= "${toIsoStringDate(endDate)}")
}
    `;
}
const MARKET_DATA_REQUEST_OPTIONS = {
  headers: {
    Accept: "application/json",
  },
};
export default memo(function ({ startDate, endDate, fcode }: EventsTableProps) {
  const { getIdTokenClaims } = useAuth0();
  const query = parametrizeQuery(startDate, endDate, fcode);
  const queryParams = new URLSearchParams({ query });
  // TODO: Remove the direct call to stardog when there's an api for events
  const { data: events, error } = useSWR(
    [
      `${process.env.REACT_APP_STARDOG_ENDPOINT}/?${queryParams.toString()}`,
      async () => await getIdTokenClaims(),
    ],
    fetcherWithToken
  );
  const { data: marketData, error: marketDataError } = useSWR(
    [
      `${process.env.REACT_APP_API}/marketData/api/dylan/quotes/v2/comp/quoteByDialect?ckey=oq1midc04n&entitlementToken=oq1midc04n4eqmztyah9w5e9ek4e8bgv&id=${fcode}&dialect=fcode&dialects=fcode&MaxInstrumentMatches=1`,
      async () => await getIdTokenClaims(),
      MARKET_DATA_REQUEST_OPTIONS,
    ],
    fetcherWithToken
  );
  return (
    <>
      {error && marketDataError ? (
        <div>Failed to load data</div>
      ) : !events || !marketData ? (
        <Loading />
      ) : (
        <MUIDataTable
          title={"Events"}
          data={createEventsTable(events, marketData)}
          columns={eventsColumns}
          options={{
            selectableRows: "none",
            responsive: "scrollMaxHeight",
            filterType: "multiselect",
            // Add explicit textLabels for table
            textLabels: {
              body: {
                noMatch: "No matching records found",
                toolTip: "Sort",
              },
              pagination: {
                next: "Next Page",
                previous: "Previous Page",
                rowsPerPage: "Rows per page:",
                displayRows: "of",
              },
              toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "row(s) selected",
                delete: "Delete",
                deleteAria: "Delete Selected Rows",
              },
            },
          }}
        />
      )}
    </>
  );
});
