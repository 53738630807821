import React from "react";
import Search from "../components/Search";

export default function () {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ width: "80%", marginTop: "10%" }}>
        <Search />
      </div>
    </div>
  );
}
