import React from "react";
import { Router, Switch } from "react-router-dom";

import PrivateRoute from "./components/PrivateRoute";
import Loading from "./components/Loading";
import SiteHeader from "./components/header";
import Home from "./views/Home";
import { useAuth0 } from "./react-auth0-spa";
import history from "./utils/history";

// styles
import "./App.css";
import Company from "./views/Company";

export default function () {
  const { loading } = useAuth0();

  if (loading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app">
        <SiteHeader />
        <div style={{ zIndex: 1, position: "relative" }}>
          <Switch>
            <PrivateRoute path="/" exact component={Home} />
            <PrivateRoute path="/:fcode" exact component={Company} />
            {/* <PrivateRoute path="/somePrivatePath" component={SomePrivateView} /> */}
          </Switch>
        </div>
      </div>
    </Router>
  );
}
