import debounce from "lodash.debounce";
import React, { useState, useEffect } from "react";
import Search from "./Search.style";
import { SearchProps, SearchResultProps } from "semantic-ui-react";
import Fuse from "fuse.js";
import companies from "../assets/companies.json";
import { useRouter } from "../utils/hooks";

type FuseResult<T> = {
  item: T;
  refIndex: number;
  score?: number;
  matches?: ReadonlyArray<any>;
};

const options = {
  shouldSort: true,
  threshold: 0.4,
  location: 0,
  distance: 10,
  minMatchCharLength: 1,
  keys: ["Alias"],
};

const fuse = new Fuse(companies, options); // "list" is the item array

async function fuzzySearch(search: string) {
  return await fuse.search(search);
}

type result = {
  Alias: string;
  Fcode: string;
};

type state = {
  isLoading: boolean;
  value: string;
};

const initialState: state = { isLoading: false, value: "" };
const initialResults: FuseResult<result>[] = [];

const resultRenderer = (props: SearchResultProps) => {
  const { item } = props;
  return <div>{item.Alias}</div>;
};

export default (props: SearchProps) => {
  const [state, setState] = useState(initialState);
  const [results, setResults] = useState(initialResults);
  const { navigate } = useRouter();
  const { isLoading, value } = state;

  function handleSearchChange(e: any, { value }: any) {
    setState((prevState) => {
      return { ...prevState, value };
    });
  }

  useEffect(() => {
    if (value.length < 1) return;
    setState({ isLoading: true, value });
    fuzzySearch(value)
      .then((result) => {
        setResults(result);
        setState({ isLoading: false, value });
      })
      .catch(() => {});
    return () => {
      return;
    };
  }, [value]);

  return (
    <Search.Bar
      size="massive"
      fluid
      loading={isLoading}
      onSearchChange={debounce(handleSearchChange, 500, {
        leading: true,
      })}
      onResultSelect={(e: any, data: { result: { item: result } }) => {
        return navigate(data.result.item.Fcode.toLowerCase());
      }}
      results={results}
      resultRenderer={resultRenderer}
      value={value}
      {...props}
    />
  );
};
