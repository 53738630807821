import React, { memo } from "react";
import useSWR from "swr";
import startCase from "lodash.startcase";
import MUIDataTable, { MUIDataTableColumnDef } from "mui-datatables";
import BlockRoundedIcon from "@material-ui/icons/BlockRounded";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import { useAuth0 } from "../react-auth0-spa";
import fetcherWithToken from "../utils/fetcher";
import sourcesData from "../assets/sources.json";
import Loading from "../components/Loading";
import Link from "@material-ui/core/Link";
import InfoIcon from "@material-ui/icons/Info";
import HtmlTooltip from "../components/HtmlTooltip";
import { Typography } from "@material-ui/core";

const DEFAULT_SOURCES: {
  [index: string]: { SourceName: string };
} = sourcesData;

interface SignalsTableProps {
  startDate: Date | null;
  endDate: Date | null;
  fcode?: string;
}

type SignalProperties = {
  [index: string]: {
    text: string;
  };
};

type SignalResult = {
  id: string;
  type: string;
  snippet: string;
  date: string;
  source: string;
  ans: string[];
  isValidated: boolean;
  signalProperties: SignalProperties;
  isCompanyStatement: boolean;
};

const signalsColumns: MUIDataTableColumnDef[] = [
  {
    name: "id",
    options: {
      display: "false",
      filter: false,
      viewColumns: false,
    },
  },
  {
    name: "type",
    label: "Signal Type",
    options: {
      filter: true,
      filterType: "multiselect",
      // TODO: This will need to be added back in if the startCase is removed
      //       from formatSignalsResults. As well, customerFilterOptions should
      //       be used, as customerFilterListRender is deprecated.
      // customFilterListRender: (value) => startCase(value),
      sort: true,
      customBodyRender: (value: string, tableMeta) => {
        const signalProperties = tableMeta.rowData[7] as SignalProperties;
        const signalKeys = Object.keys(signalProperties);
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>{startCase(value)}</span>
            {signalKeys?.length > 0 && (
              <HtmlTooltip
                title={
                  <>
                    <Typography color="inherit">Event Properties</Typography>
                    <ul style={{ listStyle: "none" }}>
                      {signalKeys.map((signalKey) => (
                        <li key={signalKey}>
                          <strong>{startCase(signalKey)}</strong>:{" "}
                          {signalProperties[signalKey].text}
                        </li>
                      ))}
                    </ul>
                  </>
                }
              >
                <InfoIcon style={{ marginLeft: "1em" }} color="primary" />
              </HtmlTooltip>
            )}
          </div>
        );
      },
    },
  },
  {
    name: "snippet",
    label: "Snippet",
    options: {
      filter: false,
    },
  },
  {
    name: "date",
    label: "Article Date",
    options: {
      filter: false,
      sortDirection: "desc",
      customBodyRender: (value: string) => {
        const date = Date.parse(value) ? new Date(value).toDateString() : null;
        return <time style={{ whiteSpace: "nowrap" }}>{date}</time>;
      },
    },
  },
  {
    name: "source",
    label: "Full Story",
    options: {
      filter: false,
      customBodyRender: (value, tableMeta) => {
        const ans: string[] = [...new Set(tableMeta.rowData[5] as string[])];
        const sources = ans
          // .filter((an) => typeof an === "string")
          .map((an) => DEFAULT_SOURCES[an.substr(0, 8)]?.SourceName);
        return (
          <ul style={{ display: "flex", flexDirection: "column" }}>
            {sources.map((source, index) => (
              <Link
                href={`https://global.factiva.com/redir/default.aspx?P=sa&an=${ans[index]}`}
                target="_blank"
                rel="noreferrer noopener"
                key={ans[index]}
              >
                {source ?? "Link"}
              </Link>
            ))}
          </ul>
        );
      },
    },
  },
  {
    name: "an",
    label: "An",
    options: {
      display: "false",
      filter: false,
      viewColumns: false,
    },
  },
  {
    name: "isValidated",
    label: "Validation",
    options: {
      filter: true,
      filterOptions: {
        names: ["Human Validated", "Unvalidated"],
        logic(isValidated, filterVal) {
          const show =
            (filterVal.indexOf("Human Validated") >= 0 && isValidated) ||
            (filterVal.indexOf("Unvalidated") >= 0 && !isValidated);
          return !show;
        },
      },
      customBodyRender: (value: boolean) => {
        return value ? (
          <CheckCircleOutlineRoundedIcon
            style={{
              color: "#64a342",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        ) : (
          <BlockRoundedIcon
            style={{
              color: "#FF6347",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        );
      },
    },
  },
  {
    name: "signalProperties",
    label: "signalProperties",
    options: {
      display: "false",
      filter: false,
      viewColumns: false,
    },
  },
];

function formatSignalsResults(results: any[]) {
  let resultObject = {} as SignalResult;
  const rows = results.reduce((acc, curr) => {
    const { id, attributes } = curr;
    const {
      publication_date,
      signal_type,
      signal_properties,
      snippet,
      source,
      article_ids,
      is_validated,
    } = attributes;

    resultObject = {
      id,
      // TODO: This should probably be done while rendering the component, but requires
      //       MUIDataTables to make that possible in filterOptions. So for now it is here.
      type: startCase(signal_type),
      snippet,
      date: publication_date,
      source: source,
      ans: article_ids?.an,
      isValidated: is_validated,
      signalProperties: signal_properties,
      isCompanyStatement: signal_properties?.is_company_statement,
    };
    acc.push(Object.values(resultObject));
    return acc;
  }, []);
  return { rows, columns: Object.keys(resultObject) };
}

function toIsoStringDate(date: Date | null) {
  return date?.toISOString().split("T")[0];
}

// This headers are only needed for this table, it's important to have them outside
// the component to avoid creating a new reference on render for the swr key
const bodyInitForSignals = { headers: { "X-Customer-Id": "covid-19" } };

export default memo(function SignalsTable({
  startDate,
  endDate,
  fcode,
}: SignalsTableProps) {
  const { getIdTokenClaims } = useAuth0();
  const { data: signals, error } = useSWR(
    [
      `${
        process.env.REACT_APP_API
      }/signals?filter[company][fcode]=${fcode}&dateBegin=${toIsoStringDate(
        startDate
      )}&dateEnd=${toIsoStringDate(endDate)}`,
      async () => await getIdTokenClaims(),
      bodyInitForSignals,
    ],
    fetcherWithToken
  );
  return (
    <>
      {error ? (
        <div>Failed to load data</div>
      ) : !signals ? (
        <Loading />
      ) : (
        <MUIDataTable
          title={"Signals"}
          data={formatSignalsResults(signals?.data).rows}
          columns={signalsColumns}
          options={{
            selectableRows: "none",
            responsive: "scrollMaxHeight",
            // Add explicit textLabels for table
            textLabels: {
              body: {
                noMatch: "No matching records found",
                toolTip: "Sort",
              },
              pagination: {
                next: "Next Page",
                previous: "Previous Page",
                rowsPerPage: "Rows per page:",
                displayRows: "of",
              },
              toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "row(s) selected",
                delete: "Delete",
                deleteAria: "Delete Selected Rows",
              },
            },
          }}
        />
      )}
    </>
  );
});
