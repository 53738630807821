import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, Theme } from "@material-ui/core";

// TODO: Make this generic
const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export default HtmlTooltip;
