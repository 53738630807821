import { useLocation, useHistory } from "react-router-dom";

function useRouter() {
  const location = useLocation();
  const history = useHistory();

  function navigate(to: string, { replace = false } = {}) {
    if (replace) {
      history.replace(to);
    } else {
      history.push(to);
    }
  }

  return {
    location,
    navigate,
  };
}

export { useRouter };
