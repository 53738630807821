import React from "react";
import { ButtonToolbar, Dropdown } from "react-bootstrap";
import { useAuth0 } from "../../react-auth0-spa";
import classNames from "classnames/bind";
import styles from "./settings.module.css";
const cx = classNames.bind(styles);

function Settings(props) {
  const { isAuthenticated, logout } = useAuth0();
  const { Toggle: DropdownToggle, Menu: DropdownMenu } = Dropdown;

  const options = [];

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  options.push(
    <Dropdown.Item
      key="signOutOption"
      eventKey="2"
      className="btn-signOut"
      onClick={() => logoutWithRedirect()}
    >
      Log Out
    </Dropdown.Item>
  );
  if (!isAuthenticated) return null;
  return (
    <div className={cx("component", props.className)}>
      <ButtonToolbar>
        <Dropdown id="settings-dropdown">
          <DropdownToggle title="">
            <img src={require("../../assets/settings.svg")} alt="Settings" />
          </DropdownToggle>
          <DropdownMenu bsStyle="link" id="dropdown-no-caret">
            {options}
          </DropdownMenu>
        </Dropdown>
      </ButtonToolbar>
    </div>
  );
}

export default Settings;
