import React from "react";
import { Link } from "react-router-dom";
import styles from "./navigation.module.css";
import classNames from "classnames/bind";
import PulsarIcon from "../../assets/pulsar.svg";

const cx = classNames.bind(styles);

export default function Navigation(props) {
  const headerLogoContent = (
    <div className={cx("header-logo-container")}>
      <Link className={cx("header-logo")} to={"/"}>
        <img
          src={PulsarIcon}
          alt="Pulsar logo"
          style={{ height: "1.3em", marginTop: "-6px" }}
        />
        &nbsp; News Graph (ALPHA DEMO)
      </Link>
    </div>
  );

  return (
    <div>
      <div className={cx("header-container")}>{headerLogoContent}</div>
    </div>
  );
}
