const fetcherWithToken = async (
  url: string,
  getToken: () => any,
  init?: RequestInit
) => {
  const tokenRaw = await getToken();
  return fetch(url, {
    ...init,
    headers: {
      Authorization: `Bearer ${await tokenRaw.__raw}`,
      ...init?.headers,
    },
  }).then((r) => r.json());
};

export default fetcherWithToken;
