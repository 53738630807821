import React, { memo, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { useParams, useHistory } from "react-router-dom";
import companies from "../assets/companies.json";
import IconButton from "@material-ui/core/IconButton";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import SignalsTable from "../components/SignalsTable";
import EventsTable from "../components/EventsTable";
import Search from "../components/Search";

const DEFAULT_THEME = createMuiTheme({
  overrides: {
    MuiGridListTile: {
      root: {
        width: "100%!important",
      },
    },
  },
});

function getCompanyName(fcode: string) {
  return companies.find(
    (company) => company.Fcode.toLowerCase() === fcode.toLowerCase()
  )?.Alias;
}

export default memo(function () {
  const history = useHistory();
  const [startDate, setStartDate] = useState<Date | null>(
    new Date("2020-01-01")
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };

  const { fcode } = useParams();
  return (
    <>
      <IconButton
        aria-label="go back"
        color="primary"
        onClick={history.goBack}
        size="medium"
      >
        <ArrowBackIcon />
      </IconButton>
      <Search size="small" />
      <div style={{ padding: "2em 1em" }}>
        <div>
          <h1>{fcode ? getCompanyName(fcode) : null}</h1>
          <div style={{ marginBottom: "1em" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                format="MMMM do, yyyy"
                variant="inline"
                margin="normal"
                id="date-picker-start"
                label="Start Date"
                value={startDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardDatePicker
                disableToolbar
                format="MMMM do, yyyy"
                variant="inline"
                margin="normal"
                id="date-picker-end"
                label="End Date"
                value={endDate}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <MuiThemeProvider theme={DEFAULT_THEME}>
          <EventsTable fcode={fcode} startDate={startDate} endDate={endDate} />
          <div style={{ marginBottom: "5em" }} />
          <SignalsTable fcode={fcode} startDate={startDate} endDate={endDate} />
        </MuiThemeProvider>
      </div>
    </>
  );
});
