import React, { useEffect } from "react";
import { Route, withRouter } from "react-router-dom";
import { useAuth0 } from "../react-auth0-spa";

export default withRouter(function (props: any) {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();
  const { Component, location } = props;

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: location.pathname },
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, location.pathname]);

  const render = (props: any) =>
    isAuthenticated ? <Component {...props} /> : null;

  return <Route path={props.path} render={render} {...props} />;
});
